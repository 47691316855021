import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4cf6dd3d"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["checked", "value", "disabled"];
const _hoisted_2 = ["tabindex"];
const _hoisted_3 = {
  key: 1,
  class: "radio_wrapper flex items-center"
};
const _hoisted_4 = ["checked", "value"];
const _hoisted_5 = ["tabindex"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $options.isCheckBox ? (_openBlock(), _createElementBlock("label", {
    key: 0,
    class: _normalizeClass(["wrapper flex items-center", $props.classes])
  }, [_createTextVNode(_toDisplayString($props.label) + " ", 1), _createElementVNode("input", {
    class: "checkbox",
    role: "checkbox",
    type: "checkbox",
    checked: $options.isChecked,
    value: $props.value,
    onChange: _cache[0] || (_cache[0] = (...args) => $options.updateInput && $options.updateInput(...args)),
    tabindex: "-1",
    disabled: $props.isDisabled
  }, null, 40, _hoisted_1), $options.isCheckBox ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "checkmark",
    tabindex: $props.tabindex,
    onKeyup: _cache[1] || (_cache[1] = _withKeys((...args) => $options.onEnter && $options.onEnter(...args), ["enter"])),
    onKeydown: _cache[2] || (_cache[2] = _withKeys((...args) => $options.onEnter && $options.onEnter(...args), ["space"]))
  }, null, 40, _hoisted_2)) : _createCommentVNode("", true)], 2)) : (_openBlock(), _createElementBlock("label", _hoisted_3, [_createTextVNode(_toDisplayString($props.label) + " ", 1), _createElementVNode("input", {
    class: "radio",
    role: "radio",
    type: "radio",
    checked: $options.isChecked,
    value: $props.value,
    onChange: _cache[3] || (_cache[3] = (...args) => $options.updateInput && $options.updateInput(...args)),
    tabindex: "-1"
  }, null, 40, _hoisted_4), _createElementVNode("span", {
    class: "radio_checkmark",
    tabindex: $props.tabindex,
    onKeyup: _cache[4] || (_cache[4] = _withKeys((...args) => $options.onEnter && $options.onEnter(...args), ["enter"]))
  }, null, 40, _hoisted_5)]));
}