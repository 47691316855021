import { mapState, mapActions, mapMutations } from 'vuex';
import { getCookiebyName } from '@/utils/cookies.js';
import { TruckIcon } from '../../commerce/blocks/svg-elements/truck-icon/index';
import AlertIcon from '../../commerce/blocks/svg-elements/alert-icon/AlertIcon.vue';
import { useDebounceFn } from '@vueuse/core';
import { CloseIcon } from '../../commerce/blocks/svg-elements/close-icon/index';
import { defineAsyncComponent, ref } from 'vue';
import i18nHelper from '../../commerce/myAccount/helper/i18n-helper.js';
import { UserHelper } from '../../../../helpers/user-helper';
import ApiClient from '../../../../plugins/AxiosPlugin';
import { LoadingDots } from '../../commerce/blocks/output-elements/loading-dots/index';
const debouncedFn = useDebounceFn(() => window.innerWidth < 1025, 1000);
export default {
  data() {
    return {
      formattedAddress: '',
      mobileAndTabletCheck: false,
      isModalOpen: false,
      isNewUser: false,
      currentUserCountry: '',
      headerTitle: "",
      hideCloseIcon: false
    };
  },
  computed: {
    isLoggedIn() {
      return this.dccUserInfo?.data?.email;
    },
    defaultAddressSelected: function () {
      return UserHelper.hasDefaultDeliveryAddress();
    },
    teleportID() {
      return this.mobileAndTabletCheck ? '#mega-menu-truck-modal' : '#truckModal';
    },
    showLoggedInTruck() {
      return this.$store.state.user.isAuthenticated && this.$store.state.user.dccUserInfo?.data?.canShowTruck;
    },
    ...mapState('user', ['isAuthenticated', 'dccUserInfo']),
    ...mapState('browser', ['country'])
  },
  components: {
    TruckIcon,
    AlertIcon,
    CloseIcon,
    LoadingDots,
    DeliveryTruckLoggedIn: defineAsyncComponent({
      loader: () => import('../../ui/delivery-truck/delivery-truck-logged-in/delivery-truck-logged-in.vue'),
      loadingComponent: LoadingDots,
      delay: 200,
      onError(error, retry, fail, attempts) {
        if (attempts <= 3) {
          retry();
        } else {
          fail();
        }
      }
    }),
    DeliveryTruckLoggedOut: defineAsyncComponent({
      loader: () => import('../../ui/delivery-truck/delivery-truck-logged-out/delivery-truck-logged-out.vue'),
      loadingComponent: LoadingDots,
      delay: 200,
      onError(error, retry, fail, attempts) {
        if (attempts <= 3) {
          retry();
        } else {
          fail();
        }
      }
    })
  },
  created() {
    window.addEventListener('resize', this.setIsMobileOrTablet);
  },
  mounted() {
    this.getCountryData().then(res => {
      window.truckCountries = res;
      this.setCurrentCountryName();
    });
    this.$refs.skeletonRef.remove();
    this.$store.dispatch('user/updateDccUserInfo');
    if (this.isAuthenticated && this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.formattedAddress && this.$store.state.user.dccUserInfo?.data?.canShowTruck) {
      this.$refs.infoIconRef.classList.remove('hide');
    } else {
      this.$refs.infoIconRef.classList.add('hide');
    }
    this.setIsMobileOrTablet();
    window.adobeDataLayer.addEventListener('adobeDataLayer:event', event => {
      if (event.event == 'otConsent') {
        if (event.detail.includes("C0003")) {
          this.$store.dispatch('browser/setLocation', {}).then(() => {
            this.setCurrentCountryName();
          });
        }
      }
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.setIsMobileOrTablet);
  },
  watch: {
    dccUserInfo: {
      deep: true,
      handler(newVal) {
        if (this.isAuthenticated) {
          this.isLoggedIn = true;
          if (!this.defaultAddressSelected && this.$store.state.user.dccUserInfo?.data?.canShowTruck) {
            this.hideCloseIcon = true;
            this.openModal();
          }
        }
        if (this.isAuthenticated && newVal?.data?.canShowTruck && newVal?.data?.defaultDeliveryAddress) {
          const defaultDeliveryAddress = {
            country: newVal.data.defaultDeliveryAddress.shipToAddress?.country,
            town: newVal.data.defaultDeliveryAddress.internationalAddress.town ? newVal.data.defaultDeliveryAddress.internationalAddress.town : newVal.data.defaultDeliveryAddress?.shipToAddress?.town,
            sapID: newVal.data.defaultDeliveryAddress?.sapCustomerID,
            companyName: newVal.data.defaultDeliveryAddress.internationalAddress.companyName ? newVal.data.defaultDeliveryAddress.internationalAddress.companyName : newVal.data.defaultDeliveryAddress?.shipToAddress?.companyName,
            formattedAddress: newVal.data.defaultDeliveryAddress.internationalAddress.formattedAddress ? newVal.data.defaultDeliveryAddress.internationalAddress.formattedAddress : newVal.data.defaultDeliveryAddress?.shipToAddress?.formattedAddress
          };
          this.$store.dispatch('address/updateAddress', defaultDeliveryAddress);
          this.getUserCountry();
          this.getUserTown();
          this.getModifiedAddress();
          if (this.formattedAddress) {
            this.$refs.infoIconRef.classList.remove('hide');
          }
        } else {
          this.$refs.infoIconRef.classList.add('hide');
        }
      }
    }
  },
  methods: {
    getLabel(key, value) {
      return !!i18nHelper.getI18nMessage(key, value) ? i18nHelper.getI18nMessage(key, value) : key;
    },
    setCurrentCountryName() {
      var countryCookie = store.getters['browser/getCookiebyName']({
        name: 'country'
      });
      if (!countryCookie) {
        countryCookie = 'US';
      }
      const country = window.truckCountries.filter(country => country.isocode === countryCookie)[0];
      this.currentUserCountry = country.value;
    },
    getCountryData() {
      return ApiClient.get('/.dow.commerce.dropdown.json', {
        params: {
          country: self.selectedCountry
        }
      }).then(res => res.data).catch(err => console.error(err));
    },
    openModal() {
      if (!this.defaultAddressSelected) {
        this.isNewUser = true;
      }
      if (this.$store.state.user.isAuthenticated && this.$store.state.user.dccUserInfo?.data?.canShowTruck) {
        this.headerTitle = this.isNewUser ? this.getLabel('truck.modal.firstTitle') : this.getLabel('delivery.address.Title');
      } else {
        this.headerTitle = this.getLabel('choose.location');
      }
      document.body.style.overflow = 'hidden';
      this.isModalOpen = true;
    },
    closeModal() {
      document.body.style.overflow = 'auto';
      this.isModalOpen = false;
      if (this.showLoggedInTruck) {
        this.$refs.deliveryTruckLoggedIn.clearModalData();
        this.$refs.deliveryTruckLoggedIn.isNewUser = false;
      }
    },
    getUserCountry() {
      const userCountry = this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.country?.name;
      this.userCountry = userCountry || '';
    },
    getUserTown() {
      const userTown = this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.town;
      this.userTown = userTown || '';
    },
    getModifiedAddress() {
      const defaultDeliveryAddress = this.$store.state.user.dccUserInfo?.data?.defaultDeliveryAddress;
      if (defaultDeliveryAddress && defaultDeliveryAddress.sapCustomerID || defaultDeliveryAddress.shipToAddress.sapCustomerID) {
        const sapCustomerID = defaultDeliveryAddress.sapCustomerID || defaultDeliveryAddress.shipToAddress.sapCustomerID.replace(/STD_/, '');
        const company = defaultDeliveryAddress.internationalAddress.companyName;
        const companyName = company ? company : defaultDeliveryAddress?.shipToAddress?.companyName;
        const address = defaultDeliveryAddress.internationalAddress.formattedAddress;
        this.formattedAddress = `${sapCustomerID} ${companyName}<br><br>${address ? address : defaultDeliveryAddress?.shipToAddress?.formattedAddress}`;
        return;
      }
      this.formattedAddress = '';
    },
    toggleAddressInfo() {
      if (!this.mobileAndTabletCheck) {
        if (this.formattedAddress) {
          this.$refs.formattedAddressRef.classList.toggle('hide');
        }
      }
    },
    setIsMobileOrTablet() {
      debouncedFn().then(value => {
        this.mobileAndTabletCheck = value;
      });
    }
  }
};